<template>
  <div class="main-box">
    <el-form class="form-inline" :inline="true">
      <el-form-item label="">
        <el-input v-model="search.keywords" placeholder="请输入姓名/手机号" size="small" clearable>
          <el-button slot="append" class="iv-search" size="small" type="primary" @click="handleSearch">搜索</el-button>
        </el-input>
      </el-form-item>
    </el-form>
    <div class="mg-bt-10">
      <el-button type="primary" size="small" @click="setGroupAll">批量设置分组</el-button>
      <el-button type="warning" size="small" @click="setLableAll()">批量设置标签</el-button>
    </div>
    <el-table
        :data="tableData"
        ref="rfTable"
        style="width: 100%"
        size="mini"
        @selection-change="handleSelectionChange">
      <el-table-column
          type="selection"
          align="center"
          width="55">
      </el-table-column>
      <el-table-column
          prop="user_id"
          label="会员信息"
          min-width="250">
        <template slot-scope="scope">
          <div class="member-box">
            <img :src="scope.row.avatar ? scope.row.avatar : source_domain + '/images/head.png'" />
            <div>
              <div>{{scope.row.name}}（{{scope.row.user_id}}）</div>
              <div>{{scope.row.mobile}}</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
          prop="refer"
          label="推荐人"
          min-width="250">
        <template slot-scope="scope">
          <div class="member-box" v-if="scope.row.refer">
            <img :src="scope.row.refer.avatar ? scope.row.refer.avatar : source_domain + '/images/head.png'" />
            <div>
              <div>{{scope.row.refer.name}}（{{scope.row.refer.user_id}}）</div>
              <div>{{scope.row.refer.mobile}}</div>
            </div>
          </div>
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column
          prop="real_name"
          label="真实姓名"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="grade_name"
          label="会员卡"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="grade_type"
          label="升级类型"
          align="center"
          width="120">
        <template slot-scope="scope">
          {{ scope.row.grade_type==1 ? '自动升级' : (scope.row.grade_type==2 ? '手动升级' : '') }}
        </template>
      </el-table-column>
      <el-table-column
          prop="grade_over_time"
          label="到期时间"
          align="center"
          width="150">
      </el-table-column>
      <el-table-column
          prop="group_name"
          label="分组"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="balance"
          label="余额"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="commission"
          label="佣金"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="integral"
          label="积分"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="ftime"
          label="注册时间"
          align="center"
          width="140">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          align="center"
          width="100">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="editMember(scope.row)" class="mg-rt-10">编辑</el-button>
          <el-dropdown class="mg-lf-10" size="mini" @command="clickDrowMenu">
            <el-button type="text" size="small">更多<i class="el-icon-arrow-down el-icon--right"></i></el-button>
            <el-dropdown-menu slot="dropdown">
<!--              <el-dropdown-item :command="{type:'info',member_id: scope.row.id}">会员详情</el-dropdown-item>-->
              <el-dropdown-item :command="{type:'integral',member_id: scope.row.id}">修改积分</el-dropdown-item>
              <el-dropdown-item divided :command="{type:'amount',member_id: scope.row.id}">修改余额</el-dropdown-item>
              <el-dropdown-item divided :command="{type:'group',member_id: scope.row.id}">设置分组</el-dropdown-item>
              <el-dropdown-item divided :command="{type:'label',member_id: scope.row.id,label_ids: scope.row.label_ids}">设置标签</el-dropdown-item>
              <el-dropdown-item divided :command="{type:'card',member_id: scope.row.id}">设置会员卡</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[10, 20, 30, 40]"
        :total="search.total">
    </el-pagination>
    <el-dialog
        title="修改积分"
        :visible.sync="showIntegralModal"
        :destroy-on-close="true"
        width="500px">
      <integral-change :memberId="member_id" :callFunc="function () { member_id=0; showIntegralModal=false; getList()}"></integral-change>
    </el-dialog>
    <el-dialog
        title="修改余额"
        :visible.sync="showAmountModal"
        :destroy-on-close="true"
        width="500px">
      <amount-change :memberId="member_id" :callFunc="function () { member_id=0; showAmountModal=false; getList()}"></amount-change>
    </el-dialog>
    <el-dialog
        title="设置分组"
        :visible.sync="showGroupModal"
        :destroy-on-close="true"
        width="500px">
      <group-set :memberId="member_ids" :callFunc="function () { member_ids=[]; showGroupModal=false; getList()}"></group-set>
    </el-dialog>
    <el-dialog
        title="设置会员卡"
        :visible.sync="showCardModal"
        :destroy-on-close="true"
        width="500px">
      <card-set :memberId="member_id" :callFunc="function () { member_id=0; showCardModal=false; getList()}"></card-set>
    </el-dialog>
    <el-dialog
        title="设置标签"
        :visible.sync="showLabelModal"
        :destroy-on-close="true"
        :before-close="function() {dataIds=[]; showLabelModal=false}"
        width="500px">
      <label-set :dataIds="dataIds" :callFunc="setLabel"></label-set>
    </el-dialog>
    <el-dialog
        title="编辑会员"
        :visible.sync="showMemberUpdateModal"
        :destroy-on-close="true"
        width="500px">
      <member-update :memberId="member_id" :callFunc="function () { member_id=0; showMemberUpdateModal=false; getList()}"></member-update>
    </el-dialog>
  </div>
</template>
<script>

import IntegralChange from "@/components/Member/IntegralChange";
import AmountChange from "@/components/Member/AmountChange";
import GroupSet from "@/components/Member/GroupSet";
import CardSet from "@/components/Member/CardSet";
import LabelSet from "@/components/Member/LabelSet";
import MemberUpdate from "@/components/Member/MemberUpdate";
export default {
  components: {
    IntegralChange,
    AmountChange,
    GroupSet,
    CardSet,
    LabelSet,
    MemberUpdate
  },
  data() {
    return {
      member_id: 0,
      member_ids: [],
      dataIds: [],
      showIntegralModal: false,
      showAmountModal: false,
      showGroupModal: false,
      showCardModal: false,
      showLabelModal: false,
      showMemberUpdateModal: false,
      info: false,
      source_domain: this.config.SOURCE_DOMAIN,
      search: {
        current_page: 1,
        total: 0,
        limit: 10,
        keywords: ''
      },
      tableData: []
    };
  },
  created() {
    this.getList();
  },
  mounted() {
  },
  computed: {},
  methods: {
    clickDrowMenu(e) {
      var _this = this
      setTimeout(function () {
        _this.member_id = e.member_id
        _this.member_ids = [e.member_id]
        if(e.type=='label') {
          _this.dataIds = e.label_ids
        }
      },100)
      if(e.type=='integral') {
        this.showIntegralModal = true
      } else if(e.type=='amount') {
        this.showAmountModal = true
      } else if(e.type=='group') {
        this.showGroupModal = true
      } else if(e.type=='card') {
        this.showCardModal = true
        console.log(e)
      } else if(e.type=='label') {
        this.showLabelModal = true
      }
    },
    getList() {
      var _this = this
      _this.showLoading()
      var param = {
        page: _this.search.current_page,
        page_size: _this.search.limit,
        keywords: _this.search.keywords
      }
      this.$api.member.memberIndex(param, function (res) {
        _this.hideLoading()
        if (res.errcode == 0) {
          _this.tableData = res.data.data
          _this.search.current_page = res.data.current_page
          _this.search.total = res.data.total
        } else {
          _this.fail(res.errmsg)
        }
      })
    },
    editMember(item) {
      var _this = this
      setTimeout(function () {
        _this.member_id = item.id
      },100)
      _this.showMemberUpdateModal = true
    },
    setGroupAll() {
      var _this = this
      var sel = this.$refs.rfTable.selection
      if (sel.length < 1) {
        _this.tips('请选择会员')
        return false
      }
      var ids = []
      sel.forEach(function (item) {
        ids.push(item.id)
      })
      this.member_ids = ids
      this.showGroupModal = true
    },
    setLableAll() {
      var _this = this
      var sel = this.$refs.rfTable.selection
      if (sel.length < 1) {
        _this.tips('请选择会员')
        return false
      }
      var ids = []
      sel.forEach(function (item) {
        ids.push(item.id)
      })
      this.member_ids = ids
      this.showLabelModal = true
    },
    setLabel(e) {
      var params = {
        ids: this.member_ids,
        label_id: e
      }
      this.$api.member.memberSetLable(params,res => {
        if (res.errcode == 0) {
          this.showLabelModal = false
          this.dataIds = []
          this.success(res.errmsg)
          this.getList()
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    handleSearch() {
      this.search.current_page = 1
      this.getList()
    },
    handleSelectionChange(rows) {
    },
    handleCurrentChange(val) {
      this.search.current_page = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.current_page = 1
      this.search.limit = val
      this.getList()
    }
  }
};
</script>
<style scoped>
</style>
