<template>
  <div class="box">
    <el-form :model="info" label-position="right" :rules="rules" ref="ruleForm" label-width="80px" size="small">
      <el-form-item label="真实姓名" prop="real_name">
        <el-input v-model="info.real_name" placeholder="请输入姓名"></el-input>
      </el-form-item>
      <el-form-item label="性别" prop="gender">
        <el-radio-group v-model="info.gender">
          <el-radio :label="1">男</el-radio>
          <el-radio :label="2">女</el-radio>
          <el-radio :label="0">未知</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="生日" prop="birthday">
        <el-date-picker
            v-model="info.birthday"
            type="date"
            placeholder="请选择日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="手机号" prop="mobile">
        <el-input v-model="info.mobile" placeholder="请输入手机号"></el-input>
      </el-form-item>
      <el-form-item label="地址" prop="address">
        <el-input v-model="info.address" placeholder="请输入地址"></el-input>
      </el-form-item>
      <el-form-item label="身份证号" prop="idcard">
        <el-input v-model="info.idcard" placeholder="请输入身份证号"></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input type="textarea" v-model="info.remark" placeholder="请输入备注"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>

export default {
  data() {
    return {
      info: {
        id: 0,
        real_name: '',
        gender: '',
        birthday: '',
        mobile: '',
        address: '',
        idcard: '',
        remark: ''
      },
      rules: {
      }
    };
  },
  created() {
  },
  mounted() {
  },
  computed: {
  },
  props: {
    memberId: Number,
    callFunc: Function
  },
  watch: {
    'memberId': {
      handler (newValue, oldValue) {
        this.info.id = newValue
        this.getMemberInfo()
      },
      deep: true
    }
  },
  methods: {
    getMemberInfo() {
      if(!this.info.id) {
        return false
      }
      this.$api.member.memberInfo({id: this.info.id}, res => {
        if(res.errcode==0) {
          this.info = res.data
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    submitForm(formName) {
      var _this = this
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          var param = _this.info
          this.$api.member.memberUpadte(param,function (res) {
            if(res.errcode == 0) {
              _this.success(res.errmsg)
              _this.callFunc()
            } else {
              _this.fail(res.errmsg)
            }
          })
        } else {
          return false;
        }
      });
    },
  }
};
</script>
<style scoped>
</style>
