<template>
  <div class="box">
    <el-form :model="info" label-position="right" :rules="rules" ref="ruleForm" label-width="80px" size="small">
      <el-form-item label="分组" prop="group_id">
        <el-select v-model="info.group_id" placeholder="请选择分组">
          <el-option
              v-for="item in groupList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>

export default {
  data() {
    return {
      groupList: [],
      info: {
        ids: [],
        group_id: '',
      },
      rules: {
        group_id: [
          { required: true, message: '请选择分组', trigger: 'blur' }
        ]
      }
    };
  },
  created() {
    this.getGroupList()
  },
  mounted() {
  },
  computed: {
  },
  props: {
    memberId: Array,
    callFunc: Function
  },
  watch: {
    'memberId': {
      handler (newValue, oldValue) {
        this.info.ids = newValue
      },
      deep: true
    }
  },
  methods: {
    getGroupList() {
      this.$api.member.memberGroupIndex({page_size: 100},res => {
        if(res.errcode == 0) {
          this.groupList = res.data.data
        }
      })
    },
    submitForm(formName) {
      var _this = this
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          var param = _this.info
          this.$api.member.memberSetGroup(param,function (res) {
            if(res.errcode == 0) {
              _this.success(res.errmsg)
              _this.callFunc()
            } else {
              _this.fail(res.errmsg)
            }
          })
        } else {
          return false;
        }
      });
    },
  }
};
</script>
<style scoped>
</style>
