<template>
  <div class="box">
    <el-form :model="info" label-position="right" :rules="rules" ref="ruleForm" label-width="80px" size="small">
      <el-form-item label="会员卡" prop="grade_id">
        <el-select v-model="info.grade_id" placeholder="请选择会员卡" style="width: 70%">
          <el-option
              v-for="item in gradeList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="到期时间" prop="time">
        <el-date-picker style="width: 70%"
            v-model="info.time"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择到期时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>

export default {
  data() {
    return {
      gradeList: [],
      info: {
        id: 0,
        grade_id: '',
        time: ''
      },
      rules: {
        grade_id: [
          { required: true, message: '请选择会员卡', trigger: 'blur' }
        ],
        time: [
          { required: true, message: '请选择到期时间', trigger: 'blur' }
        ]
      }
    };
  },
  created() {
    this.getGradeList()
  },
  mounted() {
  },
  computed: {
  },
  props: {
    memberId: Number,
    callFunc: Function
  },
  watch: {
    'memberId': {
      handler (newValue, oldValue) {
        this.info.id = newValue
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    getGradeList() {
      this.$api.member.memberGradeIndex({page_size: 100,type:2},res => {
        if(res.errcode == 0) {
          this.gradeList = res.data.data
        }
      })
    },
    submitForm(formName) {
      var _this = this
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          var param = _this.info
          this.$api.member.memberSetGrade(param,function (res) {
            if(res.errcode == 0) {
              _this.success(res.errmsg)
              _this.callFunc()
            } else {
              _this.fail(res.errmsg)
            }
          })
        } else {
          return false;
        }
      });
    },
  }
};
</script>
<style scoped>
</style>
