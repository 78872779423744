<template>
  <div class="box">
    <div class="list" v-for="(item,index) in labelList" :key="index">
      <div class="title">{{ item.title }}</div>
      <div class="item" v-for="(child,cindex) in item.child" :key="cindex" :class="{'active': child.checked}" @click="selectItem(index,cindex)">{{ child.title }}</div>
    </div>
    <el-button type="primary" @click="submitForm" style="margin-top: 20px" size="small">保存</el-button>
  </div>
</template>
<script>

export default {
  data() {
    return {
      checkIds: [],
      labelList: []
    };
  },
  created() {
    this.getList()
  },
  mounted() {
  },
  computed: {
  },
  props: {
    dataIds: Array,
    callFunc: Function
  },
  watch: {
    'dataIds': {
      handler (newValue, oldValue) {
        this.checkIds = newValue
        this.getList()
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    getList() {
      var _this = this
      this.$api.member.memberLabelTree({},res => {
        if(res.errcode == 0) {
          this.labelList = res.data.filter(function (item) {
            var child = item.child
            for(var i=0; i<child.length; i++) {
              child[i].checked = _this.checkIds && _this.checkIds.indexOf(child[i].id) > -1
            }
            item.child
            return item
          })
        }
      })
    },
    selectItem(index,cindex) {
      this.labelList[index].child[cindex].checked = !this.labelList[index].child[cindex].checked
    },
    submitForm() {
      var _this = this
      var ids = []
      this.labelList.forEach(function (item) {
        item.child.forEach(function (child) {
          if(child.checked) {
            ids.push(child.id)
          }
        })
      })
      _this.callFunc(ids)
    },
  }
};
</script>
<style scoped>
.box { margin-top: -20px;}
.list .title {
  font-size: 13px;
  font-weight: bold;
  margin: 10px 0;
}
.list .item {
  display: inline-block;
  border-radius: 4px;
  border: 1px solid #999;
  padding: 3px 10px;
  margin: 0 10px 6px 0;
  cursor: pointer;
}
.list .item.active {
  background-color: #2d8cf0;
  color: #fff;
  border-color: #2d8cf0;
}
</style>
